<template>
  <FloatLabel variant="on">
    <Select
      v-model="selectedServiceAgreement"
      showClear
      option-label="name"
      :options="availableServiceAgreements"
      class="w-full"
      @change="onServiceAgreementChanged"
      data-testid="bring-service-agreement"
      pt:list:data-testid="bring-service-agreement-list"
    />
    <Select
      v-if="bring"
      v-model="selectedShippingService"
      option-label="serviceName"
      :options="availableServices ?? [bring.bringShippingService]"
      class="w-full"
      data-testid="freight-service"
      @change="onServiceChanged"
      pt:list:data-testid="freight-service-list"
    />

    <label for="bring-service-agreement" class="font-bold text-sm">
      {{ t(`freight.service-agreement`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import Select, { type SelectChangeEvent } from "primevue/select";

import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { useBringIntegration } from "@/bring-integration/BringIntegrationService";
import { useBringServiceAgreements } from "../BringServiceAgreementService";
import { Bring, BringServiceAgreement, BringShippingService } from "@/bring-integration/Bring";

const { t } = useI18n();
const bringIntegrationService = useBringIntegration();
const bringServiceAgreementService = useBringServiceAgreements();
const bring = defineModel<Bring | null>("bring");
const selectedServiceAgreement = ref<BringServiceAgreement | null>();
const availableServices = ref<BringShippingService[]>();
const availableServiceAgreements = ref<BringServiceAgreement[]>();
const selectedShippingService = ref<BringShippingService | null>();

const onServiceAgreementChanged = async (event: SelectChangeEvent) => {
  if (event.value === null) {
    bring.value = null;
    selectedShippingService.value = null;
  } else {
    const tmpBring = new Bring();
    tmpBring.bringServiceAgreement = event.value;
    bring.value = tmpBring;
    availableServices.value = await bringIntegrationService.getShippingServicesByCustomerNumber(
      event.value.customerNumber,
    );
  }
};

const onServiceChanged = (event: SelectChangeEvent) => {
  if (bring.value) bring.value.bringShippingService = event.value;
};

onMounted(async () => {
  if (bring.value) {
    selectedServiceAgreement.value = bring.value.bringServiceAgreement;
    selectedShippingService.value = bring.value.bringShippingService;
    availableServiceAgreements.value = [bring.value.bringServiceAgreement];
    availableServices.value = await bringIntegrationService.getShippingServicesByCustomerNumber(
      bring.value.bringServiceAgreement.customerNumber,
    );
  }

  const serviceAgreements = await bringServiceAgreementService.getAllServiceAgreements();

  availableServiceAgreements.value = serviceAgreements.map((y) => {
    //Primevue dropdown will trip if the object is not the same reference
    if (bring.value && y.id === bring.value.bringServiceAgreement.id) {
      return bring.value.bringServiceAgreement;
    }
    const serviceAgreement = new BringServiceAgreement();
    serviceAgreement.id = y.id;
    serviceAgreement.clientURL = y.clientURL;
    serviceAgreement.customerNumber = y.customerNumber;
    serviceAgreement.name = y.name;
    serviceAgreement.companyId = y.companyId;

    return serviceAgreement;
  });
});
</script>
