<template>
  <Dialog
    v-model:visible="visible"
    :header="t(`freight.header-edit`)"
    :modal="true"
    class="c-freight-dialog"
    pt:root:data-testid="freight-method-edit-dialog"
    :pt="{
      title: 'w-11/12 text-center',
    }"
  >
    <div class="grid grid-cols-12 gap-4 mt-2">
      <div class="flex col-span-12 md:col-span-7">
        <div class="formgrid grid grid-cols-12">
          <div class="field col-span-12 mb-4">
            <Name v-model:name="freightMethod.name" />
          </div>
          <div class="field col-span-12 mb-4">
            <ShortName v-model:shortName="freightMethod.shortName" />
          </div>
          <div class="field col-span-12 mb-4">
            <Carrier v-model:carrier="freightMethod.carrier" />
          </div>
          <div class="field col-span-12">
            <BringServiceAgreement v-model:bring="freightMethod.bring" />
          </div>
        </div>
      </div>
      <div class="flex col-span-12 md:col-span-5">
        <div class="formgrid grid grid-cols-12 gap-4">
          <div class="field col-span-12 mb-1">
            <Available
              v-model:forCustomers="freightMethod.available.forCustomers"
              v-model:forSuppliers="freightMethod.available.forSuppliers"
              v-model:forWebshop="freightMethod.available.forWebshop"
            />
          </div>
          <div class="field col-span-12">
            <Tax v-model:tax="freightMethod.tax" @setInitialTax="setInitialTax" />
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <Button
          :label="t(`common.cancel`)"
          data-testid="cancel-btn"
          @click="onCancel"
          class="mr-6"
          severity="cancel"
          text
          icon="pi pi-times"
        />
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.delete`)"
            data-testid="delete-btn"
            @click="confirmDelete"
            class="p-button-danger mr-6"
            :disabled="saving"
            icon="pi pi-trash"
          />
          <ConfirmPopup data-testid="delete-confirm" />

          <Dialog
            data-testid="unsaved-changes-dialog"
            v-model:visible="unsavedChangesDialogVisible"
            :header="t('common.unsaved-changes-header')"
            :style="{ width: '25vw' }"
            position="center"
            :modal="true"
          >
            <p class="p-6">
              {{ t("common.unsaved-changes-text") }}
            </p>
            <template #footer>
              <Button :label="t('common.cancel')" @click="unsavedChangesDialogVisible = false" text />
              <Button :label="t('common.ignore')" @click="visible = false" text />
              <Button :label="t('common.save')" icon="pi pi-check" @click="onUpdateFreightMethod" />
            </template>
          </Dialog>
          <Button
            :label="t(`common.save`)"
            data-testid="save-btn"
            @click="onUpdateFreightMethod"
            :disabled="saving"
            icon="pi pi-check"
          />
        </div>
      </div>
      <div v-if="errorReason !== ''" class="p-error flex justify-start mt-2" data-testid="available-error">
        {{ errorReason }}
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { useConfirm } from "primevue/useconfirm";
import Name from "../components/Name.vue";
import ShortName from "../components/ShortName.vue";
import Carrier from "../components/Carrier.vue";
import Available from "../components/Available.vue";
import Tax from "../components/Tax.vue";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { useFreightMethodStore } from "@/repositories/freight-method/FreightMethodStore";
import { storeToRefs } from "pinia";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { FreightMethodTax } from "@/repositories/freight-method/model/FreightMethodTax";
import BringServiceAgreement from "@/bring-service-agreements/components/BringServiceAgreement.vue";

const freightMethodStore = useFreightMethodStore();
const { saving } = storeToRefs(freightMethodStore);

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const errorReason = ref("");
const unsavedChangesDialogVisible = ref();
const previouslyFocusedInput = ref<HTMLInputElement | null>(null);

const props = defineProps<{
  freightMethod: FreightMethod;
  showDialog: boolean;
}>();

const freightMethod = ref<FreightMethod>(cloneDeep(props.freightMethod));
const initialFreightMethod = ref<FreightMethod>(cloneDeep(freightMethod.value));

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

//Nested validation
const val = useValidate();

const onUpdateFreightMethod = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  try {
    await freightMethodStore.updateFreightMethod(freightMethod.value);
    toast.add({
      severity: "success",
      summary: t("freight.toast.update-success.summary"),
      detail: t("freight.toast.update-success.detail", { name: freightMethod.value.name }),
    });
  } finally {
    visible.value = false;
  }
};

const confirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("freight.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      onDeleteFreightMethod();
    },
  });
};

const onDeleteFreightMethod = async () => {
  if (
    freightMethod.value.available.forCustomers ||
    freightMethod.value.available.forSuppliers ||
    freightMethod.value.available.forWebshop
  ) {
    errorReason.value = t("freight.in-use");
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  await freightMethodStore.deleteFreightMethod(freightMethod.value.id);

  toast.add({
    severity: "success",
    summary: t("freight.toast.delete-success.summary"),
  });

  visible.value = false;
};

const setInitialTax = (tax: FreightMethodTax) => {
  initialFreightMethod.value.tax = tax;
  freightMethod.value.tax = tax;
};

const hasUpdates = computed(() => {
  return !isEqual(freightMethod.value, initialFreightMethod.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    visible.value = false;
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopImmediatePropagation();
    if (unsavedChangesDialogVisible.value) {
      unsavedChangesDialogVisible.value = false;
      previouslyFocusedInput.value?.focus();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
    visible.value = false;
  }
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeydown);
});
onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});
</script>

<style lang="scss">
.c-freight-dialog {
  width: 90vw;

  @media screen and (min-width: 992px) {
    width: 60vw;
    max-width: 45rem;
  }
}
</style>
