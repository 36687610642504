import { httpClient, type AuthHeaders } from "@cumulus/http";
import { FreightMethod } from "./model/FreightMethod";

class FreightMethodApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/freight-methods`
        : `${import.meta.env.VITE_APP_API_URL as string}/freight-methods`;
  }
  public async getAll(authHeaders: AuthHeaders): Promise<FreightMethod[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async add(authHeaders: AuthHeaders, freight: FreightMethod): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .post("/", freight)
      .then((response) => response.data);
  }

  public async update(authHeaders: AuthHeaders, freight: FreightMethod): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", freight);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }
}
const freightMethodApi = new FreightMethodApi();
export { freightMethodApi };
