import { httpClient, type AuthHeaders } from "@cumulus/http";
import { BringShippingService } from "./Bring";

class BringIntegrationApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/bring-integration`
        : `${import.meta.env.VITE_APP_API_URL as string}/bring-integration`;
  }
  public async getShippingServicesByCustomerNumber(
    authHeaders: AuthHeaders,
    customerNumber: string,
  ): Promise<BringShippingService[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/shipping-services/${customerNumber}`)
      .then(({ data }) => data);
  }
}
const bringIntegrationApi = new BringIntegrationApi();
export { bringIntegrationApi };
