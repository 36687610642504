import { httpClient, type AuthHeaders } from "@cumulus/http";
import { NewBringServiceAgreement } from "./model/NewBringServiceAgreement";
import { BringServiceAgreement } from "./model/BringServiceAgreement";

class BringServiceAgreementsApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/bring-service-agreements`
        : `${import.meta.env.VITE_APP_API_URL as string}/bring-service-agreements`;
  }

  public async getAllBringServiceAgreements(authHeaders: AuthHeaders): Promise<BringServiceAgreement[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async addBringServiceAgreement(
    authHeaders: AuthHeaders,
    newServiceAgreement: NewBringServiceAgreement,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .post("", newServiceAgreement)
      .then(({ data }) => data);
  }
  public async updateBringServiceAgreement(
    authHeaders: AuthHeaders,
    serviceAgreement: BringServiceAgreement,
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .put("", serviceAgreement)
      .then(({ data }) => data);
  }
  public async deleteBringServiceAgreement(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders)
      .delete(id)
      .then(({ data }) => data);
  }
}
const bringServiceAgreementApi = new BringServiceAgreementsApi();
export { bringServiceAgreementApi };
